<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-card>
      <b-form v-on:submit.prevent.self="submit">
        <b-row>
          <b-col sm="3">
            <b-form-group>
              <label for="from-date">Transaction From Date :</label>
              <b-form-input type="date" v-model="FromDate"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group>
              <label for="to-date">Transaction To Date :</label>
              <b-form-input type="date" v-model="ToDate"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="userPermission" sm="3">
            <b-form-group>
              <label for="from-date">Select Cinema Name</label>
              <b-form-select
                required
                v-model="cinemasObject.selectedCinema"
                id="cinemas"
                :plain="true"
                text-field="CinemaName"
                value-field="CinemaID"
                :options="cinemasObject.cinemaList"
                @change.native="onChangeCinemaDropdwon($event.target.value)"
              >
                <template slot="first">
                  <option :value="'0'" disabled>-- Select Cinema --</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="userPermission" sm="3">
            <b-form-group>
              <label for="from-date">Select Movie</label>
              <b-form-select
                required
                v-model="cinemasObject.selectedMovie"
                id="cinemas"
                :plain="true"
                text-field="Movie"
                value-field="MovieID"
                :options="cinemasObject.moviesList"
              >
                <!-- @change.native="onChangeCinemaDropdwon($event.target.value)" -->
                <template slot="first">
                  <option :value="'0'" disabled>-- Select Movie --</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group>
              <b-button class="mt-4" type="submit" size="sm" variant="primary">
                <i class="fa fa-dot-circle-o"></i> Get Reports
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <!-- ShowReportsDetails -->
      <b-card-header>
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>Transaction Report
          </b-col>
          <b-col class="col d-flex justify-content-end">
            <!--<b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false: true"
              v-on:click="onDownloadPDF('PDF')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as PDF
            </b-button>-->
            &nbsp;&nbsp;
            <b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false: true"
              v-on:click="onDownloadExcel('XLSX')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as XLSX
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div>
          <v-client-table
            :columns="columns"
            :data="data"
            :options="options"
            :theme="theme"
            id="dataTable"
          >
            <div slot="Action" slot-scope="props">
              <b-button
                type="submit"
                @click="confirmPopup(props.row.TransactionSummaryID)"
                variant="success"
                class
                block
              >Refund</b-button>
            </div>
            <!--<div slot="Action" slot-scope="props">
              <b-button
                :disabled="props.row.Flag"
                @click="submitTransferId(props.index,props.row.BookingID)"
                type="submit"
                variant="success"
                block
              >Resend Ticket</b-button>
            </div>-->
          </v-client-table>
        </div>
        <div class="mt-2">
          <v-client-table
            :columns="columns1"
            :data="data1"
            :options="options1"
            :theme="theme1"
            id="dataTable"
          ></v-client-table>
        </div>
        <div class="mt-2">
          <v-client-table
            :columns="columns2"
            :data="data2"
            :options="options2"
            :theme="theme2"
            id="dataTable"
          ></v-client-table>
        </div>
        <div class="mt-2">
          <v-client-table
            :columns="columns3"
            :data="data3"
            :options="options3"
            :theme="theme2"
            id="dataTable"
          ></v-client-table>
        </div>
      </b-card-body>
    </b-card>

    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      title="Confirmation"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>Are you sure you want refund this transaction.</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import MasterService from "@/services/MasterService";
import OrganizationService from "@/services/OrganizationService";
import ReportService from "@/services/ReportService";
import CinemaService from "@/services/CinemaService";
import MovieService from "@/services/MovieService";

// jspdf-autotable
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName,WANASHAdashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";

export default {
  name: "TransactionReport",
  components: {
    Event,
    cSwitch
  },
  data: function() {
    return {
      // Saif
      userPermission: true,
      RefundID: "",
      cinemasObject: {
        cinemaList: [],
        selectedCinema: "0",
        moviesList: [],
        selectedMovie: "0"
      },

      // Saif
      ToDate: "",
      FromDate: "",

      ShowDate: "",
      report: "",
      columns:
        localStorage.getItem("UserRole") == "Cinema"
          ? [
              "Sr",
              "TransactionDate",
              "TicketakBookingID",
              "TypeOfCard",
              // "CinemaName",
              "Theatre",
              "ShowDate",
              "MovieName",
              "ShowTime",
              "NoOfTickets",
              "GrossTransactionValue",
              "ShowbizzReceiptNumber",
              "BookStatus",
              "UserEmail",
              "UserMobile",
              // "AuthCode",
              "TransactionValue"
              //   "Action"
            ]
          : [
              "Sr",
              "TransactionDate",
              "TransactionTime",
              "TicketakBookingID",
              "TypeOfCard",
              // "CinemaName",
              "Theatre",
              "ShowDate",
              "MovieName",
              "ShowTime",
              "NoOfTickets",
              "GrossTicketPrice",
              "InternetHandlingCharges",
              "VATOnInternetHandlingCharges",
              "GrossTransactionValue",
              "GTVDifference",
              "PGCharges",
              "PaymentGatewayChargesOnGTV",
              "PGCommissionFromExcel",
              "CommissionDifference",
              "VATOnPaymentGatewayCharges",
              "TotalPGChargesInclVAT",
              "NetReceivableFromPG",
              "AmountReceived",
              "DateOfReceipt",
              "PGChargesOnGrossTicketPrice",
              "VATOnPGOnGTP",
              "PGChargesOnIHC",
              "PGChargesOnFNB",
              "VatOnPGOnFNB",
              "VATOnPGOnIHC",
              "AmountPayableToCinema",
              "PaymentDueDateToCinema",
              "ActualPaymentDate",
              "ShareIHCPayableToCinema",
              "BookStatus",
              "UserEmail",
              "UserMobile",
              "OrderID",
              "ReferenceNumber",
              "addtionalcharges",
              "addtionalremark",
              "promotional_discount",
              "promotionremark",
              "fnbamount",
              "fnbitem",
              "FnBshare",
              "AmountPayableToCinemaConsideringFNB",
              "Action"
              // "AuthCode",
              // "TicketPrice"
            ],
      //TranscationReport
      data: [],
      options: {
        headings: {
          Sr: "Sr. No.",
          TransactionDate: "Date of Transaction",
          TransactionTime: "Time of Transaction",
          TicketakBookingID: "Booking ID",
          TypeOfCard: "Type of Card",
          CinemaChain: "Cinema Chain",
          Theatre: "Cinema Name",
          ShowDate: "Screening Date",
          MovieName: "Name of Movie",
          ShowTime: "Showtime",
          NoOfTickets: "Number of Tickets",
          GrossTicketPrice: "Gross Ticket Price",
          InternetHandlingCharges: "Internet Handling Charges",
          VATOnInternetHandlingCharges: "VAT On Internet Handling Charges",
          GrossTransactionValue: "Gross Transaction Value",
          GTVDifference: "GTV Recon.",
          PGCharges: "% of PG Charges",
          PaymentGatewayChargesOnGTV: "PG Charges - On GTV",
          PGCommissionFromExcel: "PG Commission From Excel",
          CommissionDifference: "Commission Difference",
          VATOnPaymentGatewayCharges: "VAT on PG Charges",
          TotalPGChargesInclVAT: "Total PG Charges including VAT",
          NetReceivableFromPG: "Net Receivable from PG",
          AmountReceived: "Amount Received",
          DateOfReceipt: "Date of Receipt",
          PGChargesOnGrossTicketPrice: "PG Charges on Gross Ticket Price",
          VATOnPGOnGTP: "VAT on PG on GTP",
          PGChargesOnIHC: "PG Charges on IHC",
          VATOnPGOnIHC: "VAT on PG on IHC",
          PGChargesOnFNB: "PG Charges On F&B",
          VatOnPGOnFNB: "VAT On PG On F&B",
          AmountPayableToCinema: "Amount Payable to Cinema Chain",
          PaymentDueDateToCinema: "Due date of payment to Cinema Chain",
          ActualPaymentDate: "Actual payment Date",
          ShareIHCPayableToCinema: "Share of IHC Payable to Cinema Chain",
          BookStatus: "Book Status",
          CinemaName: "Cinema Name",
          UserEmail: "User Email",
          UserMobile: "User Mobile",
          OrderID: "OrderID",
          ReferenceNumber: "Reference Number",
          addtionalcharges: "Addtional Charges",
          addtionalremark: "Addtional Remark",
          promotional_discount: "Promotional Discount",
          promotionremark: "Promotion Remark",
          fnbamount: "F&B Amount",
          fnbitem: "F&B Item",
          FnBshare: "F&B Share",
          AmountPayableToCinemaConsideringFNB:
            "Amount Payable To Cinema Considering F&B"
          // AuthCode: "Auth Code",
          // TicketPrice: "Ticket Price"
        },

        sortable: [
          "Sr",
          "TransactionDate",
          "TicketakBookingID",
          "ShowbizzReceiptNumber",
          "BookStatus",
          "CinemaName",
          "GrossTransactionValue",
          // "AuthCode",
          "UserEmail",
          "UserMobile",
          "ShowDate",
          "ShowTime",
          "MovieName",
          "NoOfTickets",
          "TransactionValue",
          "ConvenienceFee",
          "TicketPrice"
        ],
        filterable: [
          "Sr",
          "TransactionDate",
          "TransactionTime",
          "TicketakBookingID",
          "ShowbizzReceiptNumber",
          "BookStatus",
          "CinemaName",
          "GrossTransactionValue",
          "GTVDifference",
          // "AuthCode",
          "UserEmail",
          "UserMobile",
          "ShowDate",
          "ShowTime",
          "MovieName",
          "NoOfTickets",
          "ConvenienceFee",
          "TransactionValue",
          "TicketPrice"
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      downloadOptions: [
        {
          text: "Select type",
          value: ""
        },
        {
          text: "xls",
          value: "xls"
        },
        {
          text: "pdf",
          value: "pdf"
        }
      ],
      downloadType: "",
      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      //ShowReportsDetails
      columns1: ["SuccessNumberOfTickets", "SuccessPrice"],
      data1: [],
      options1: {
        headings: {
          SuccessNumberOfTickets: "No of Tickets",
          SuccessPrice: "Success Total Ticket Price + Convenience Fee"
        }
      },
      columns2: ["FailedNumberOfTickets", "FailedPrice"],
      data2: [],
      options2: {
        headings: {
          FailedNumberOfTickets: "No of Tickets",
          FailedPrice: "Failed Total Ticket Price + Convenience Fee"
        }
      },
      columns3: ["RefundedNumberOfTickets", "RefundedPrice"],
      data3: [],
      options3: {
        headings: {
          RefundedNumberOfTickets: "No of Tickets",
          RefundedPrice: "Refunded Total Ticket Price + Convenience Fee"
        }
      }
    };
  },
  beforeMount() {
    if (
      localStorage.getItem("UserRole") &&
      localStorage.getItem("UserRole") == "Cinema"
    ) {
      this.userPermission = false;
    } else {
      this.getCinemasDDL(0);
      this.getMoviesAccordingCinema(null);
    }
  },
  mounted() {
    this.submit();
  },
  methods: {
    // Saif
    onDownloadExcel: function(type) {
      if (this.data.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.data);
        var dataTwo = XLSX.utils.json_to_sheet(this.data1);
        var dataThree = XLSX.utils.json_to_sheet(this.data2);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, "TRANSACTIONS"); // sheetAName is name of Worksheet
        XLSX.utils.book_append_sheet(wb, dataTwo, "Success");
        XLSX.utils.book_append_sheet(wb, dataThree, "Failed");
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_SHOW_DATE_REPORT_${new Date().toDateString()}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    onDownloadPDF: function(type) {
      if (this.data.length > 0) {
        let mainArray = [];
        let arrayTwo = [];
        let arrayThree = [];
        let HeadTwo = ["SuccessNumberOfTickets", "SuccessPrice"];
        let HeadThree = ["FailedNumberOfTickets", "FailedPrice"];
        this.data.map(x => {
          let tempArray = [];
          this.columns.map(y => {
            if (x.hasOwnProperty(y)) {
              tempArray.push(x[y]);
            }
          });
          mainArray.push(tempArray);
        });
        this.data1.map(x => {
          let tempArray = [];
          HeadTwo.map(y => {
            if (x.hasOwnProperty(y)) {
              tempArray.push(x[y]);
            }
          });
          arrayTwo.push(tempArray);
        });
        this.data2.map(x => {
          let tempArray = [];
          HeadThree.map(y => {
            if (x.hasOwnProperty(y)) {
              tempArray.push(x[y]);
            }
          });
          arrayThree.push(tempArray);
        });
        var imgData = BASE64IMAGE;

        var doc = new jsPDF("l", "px", [1000, 600]);

        doc.addImage(imgData, "JPEG", 280, 0, 150, 100);
        doc.text(260, 100, `${WANASHAdashboardName.toUpperCase()} SHOW DATE REPORT`);
        doc.setFontSize(17);

        doc.autoTable({
          head: [this.columns],
          theme: "grid", // 'striped'|'grid'|'plain'
          body: mainArray,
          margin: { top: 120 },
          headStyles: {
            fillColor: pdfFillColor // [47, 23, 73]
          }
        });
        doc.autoTable({
          head: [HeadTwo],
          theme: "grid", // 'striped'|'grid'|'plain'
          body: arrayTwo,
          headStyles: {
            fillColor: pdfFillColor
          }
        });
        doc.autoTable({
          head: [HeadThree],
          theme: "grid", // 'striped'|'grid'|'plain'
          body: arrayThree,
          headStyles: {
            fillColor: pdfFillColor
          }
        });
        doc.save("SHOW_DATE_REPORT_" + new Date().toDateString() + ".pdf");
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    onChangeCinemaDropdwon: function(cinema) {
      this.getMoviesAccordingCinema(cinema);
    },

    getMoviesAccordingCinema(id) {
      this.cinemasObject.moviesList = [];
      let payload = {};
      if (this.FromDate.length > 0) {
        payload["FromDate"] = this.FromDate;
      }
      if (this.ToDate.length > 0) {
        payload["ToDate"] = this.ToDate;
      }
      if (id) {
        payload["CinemaID"] = id;
      }

      MovieService.getMoviesAccordingCinemaForTran(payload)
        .then(response => {
          const { data } = response;
          if (data && data.Status) {
            this.cinemasObject.moviesList = data.Movies;
          } else {
            // Show Error Message:
            console.log(data.message || "Please try again");
          }
        })
        .catch(error => {
          console.log("Error: ", error);
        });
    },

    getCinemasDDL: function(OrgID) {
      // let res = await CinemaService.getCinemasDDL(id);
      //MovieService.getMoviesAccordingCinemaForTran({})
      this.cinemasObject.cinemaList = [];
      let payload = {
        OrgID
      };
      CinemaService.getCinemasDDL(payload)
        .then(response => {
          const { data } = response;
          if (data && data.Status) {
            //this.cinemasObject.cinemaList = data.Cinemas;
            this.cinemasObject.cinemaList = data.CinemaList;
          } else {
            this.showMessageSnackbar(data.Message || "Please try again");
          }
        })
        .catch(error => {
          console.log("Catch on AddCinema-OnSubmit Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    submitTransferId: function(index, TransactionID) {
      let payload = {
        TransactionID
      };
      // let res = await ReportService.sendMail(payload)
      ReportService.sendMail(payload)
        .then(response => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    submit: function() {
      let payload = {};
      if (this.FromDate.length > 0) {
        payload["FromDate"] = this.FromDate;
      }
      if (this.ToDate.length > 0) {
        payload["ToDate"] = this.ToDate;
      }
      if (this.cinemasObject.selectedCinema !== "0") {
        payload["CinemaID"] = this.cinemasObject.selectedCinema;
      }
      if (this.cinemasObject.selectedMovie !== "0") {
        payload["MovieID"] = this.cinemasObject.selectedMovie;
      }

      ReportService.getTransactionDateReport(payload)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            this.data = data.TransReportsDetails.Data;
            this.data1 = [data.TransReportsDetails.SuccessData];
            this.data2 = [data.TransReportsDetails.FailedData];
            this.data3 = [data.TransReportsDetails.RefundedData];

            //this.getCinemasDDL(payload);
            this.getMoviesAccordingCinema(this.cinemasObject.selectedCinema);
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Catch on 1 ", error);

          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    confirmPopup: function(ID) {
      this.RefundID = ID;
      console.log(this.RefundID);
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.RefundID = "";
      this.$refs[ref].hide();
    },

    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      if (this.RefundID != "") {
        console.log("Called", this.RefundID);
        let payload = {};
        payload["TransactionSummaryID"] = this.RefundID;
        ReportService.RefundTransaction(payload)
          .then(response => {
            const { data } = response;
            if (data.Status) {
              //this.NonReconciled = data.Data;
              this.submit();
              this.RefundID = "";
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch(error => {
            console.log("Catch on 1 ", error);

            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      } else {
        this.showMessageSnackbar(
          "Oops! Something went wrong. kindly contact your administrator"
        );
      }
    },
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
</style>
